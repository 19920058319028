<template>
	<div class="signup">
		<div class="signup--wrapper">
			<div class="signup--left">
				<div class="left--wrap">
					<h2>
						<span>{{$t('signup.titolo_login')}}</span>
					</h2>
					<router-link class="link--mouse-small" :to="'/' + $i18n.locale + '/login'">{{$t('signup.login_button')}}</router-link>
				</div>
			</div>
			<div class="signup--right">
				<div class="right--wrap">
					<h1>{{$t('signup.titolo_signup')}}</h1>
					<div class="form">
						<form @submit.prevent="sendSignupForm">
							<div class="form--item half left">
								<label for="signup-name">{{$t('generale.form.nome')}}</label>
								<input type="text" id="signup-name" v-model="signupName">
								<span class="error" v-if="fieldErrors.name">{{fieldErrors.name}}</span>
							</div>
							<div class="form--item half right">
								<label for="signup-surname">{{$t('generale.form.cognome')}}</label>
								<input type="text" id="signup-surname" v-model="signupSurname">
								<span class="error" v-if="fieldErrors.surname">{{fieldErrors.surname}}</span>
							</div>
							<div class="form--item half left">
								<label for="signup-rsoc">{{$t('generale.form.rag_sociale')}}</label>
								<input type="text" id="signup-rsoc" v-model="signupRsoc">
								<span class="error" v-if="fieldErrors.rsoc">{{fieldErrors.rsoc}}</span>
							</div>
							<div class="form--item half right">
								<label for="signup-piva">{{$t('generale.form.p_iva')}}</label>
								<input type="text" id="signup-piva" v-model="signupPiva">
								<span class="error" v-if="fieldErrors.piva">{{fieldErrors.piva}}</span>
							</div>
							<div class="form--item third left">
								<label for="signup-country">{{$t('generale.form.nazione')}}</label>
								<input type="text" id="signup-country" v-model="signupCountry">
								<span class="error" v-if="fieldErrors.country">{{fieldErrors.country}}</span>
							</div>
							<div class="form--item third middle">
								<label for="signup-prov">{{$t('generale.form.provincia')}}</label>
								<input type="text" id="signup-prov" v-model="signupProv">
								<span class="error" v-if="fieldErrors.prov">{{fieldErrors.prov}}</span>
							</div>
							<div class="form--item third right">
								<label for="signup-city">{{$t('generale.form.citta')}}</label>
								<input type="text" id="signup-city" v-model="signupCity">
								<span class="error" v-if="fieldErrors.city">{{fieldErrors.city}}</span>
							</div>
							<div class="form--item twothird left">
								<label for="signup-address">{{$t('generale.form.indirizzo')}}</label>
								<input type="text" id="signup-address" v-model="signupAddress">
								<span class="error" v-if="fieldErrors.address">{{fieldErrors.address}}</span>
							</div>
							<div class="form--item third right">
								<label for="signup-cap">{{$t('generale.form.cap')}}</label>
								<input type="text" id="signup-cap" v-model="signupCap">
								<span class="error" v-if="fieldErrors.cap">{{fieldErrors.cap}}</span>
							</div>
							<div class="form--item half left">
								<label for="signup-tel">{{$t('generale.form.tel')}}</label>
								<input type="text" id="signup-tel" v-model="signupTel">
								<span class="error" v-if="fieldErrors.tel">{{fieldErrors.tel}}</span>
							</div>
							<div class="form--item half right eml">
								<label for="signup-mail">{{$t('generale.form.mail')}}</label>
								<input type="email" id="signup-mail" v-model="signupMail">
								<span class="error" v-if="fieldErrors.mail">{{fieldErrors.mail}}</span>
							</div>
							<div class="form--item half left usr">
								<label for="signup-username">{{$t('generale.form.username')}}</label>
								<input type="text" id="signup-username" v-model="signupUsername">
								<span class="error" v-if="fieldErrors.username">{{fieldErrors.username}}</span>
							</div>
							<div class="form--item half right">
								<label for="signup-password">{{$t('generale.form.password')}}</label>
								<input type="password" id="signup-password" v-model="signupPassword">
								<EyeSVG :show="iconShow" class="showPass link--mouse-small" @click.native="togglePassword" />
								<span class="error" v-if="fieldErrors.name">{{fieldErrors.password}}</span>
							</div>
							<div class="form--item privacy" :class="{ 'error' : fieldErrors.privacy }">
								<input type="checkbox" v-model="signupPrivacy" id="signup-privacy">								
								<label for="signup-privacy">
									<div class="check" :class="{ 'selected' : signupPrivacy }"></div>
									{{$t('generale.form.privacy_signup')}}
								</label>
							</div>
							<button @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')" class="link--mouse-small" type="submit">{{$t('signup.signup_button')}}</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="response">
			<div class="response--wrapper">
				<h2>
					<span v-for="(text, index) in $t('signup.titolo_risultato').split('#')" :key="index">{{text}}</span>
				</h2>
				<p v-for="(text, index) in $t('signup.testo_risultato').split('#')" :key="index">{{text}}</p>
				<router-link class="link--mouse-small" :to="'/' + $i18n.locale + '/login'">{{$t('signup.responce_login_button')}}</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import EyeSVG from '@/components/elements/EyeSVG';
//import Errors from '@/components/Errors';
import gsap from 'gsap';
import { mapActions } from 'vuex';
export default {
	data() {
		return {
			iconShow: true,
			signupName: '',
			signupSurname: '',
			signupRsoc: '',
			signupPiva: '',
			signupCountry: '',
			signupProv: '',
			signupCity: '',
			signupAddress: '',
			signupCap: '',
			signupTel: '',
			signupMail: '',
			signupUsername: '',
			signupPassword: '',
			signupPrivacy: false,
			//eslint-disable-next-line
			reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
			fieldErrors: {
				name: '',
				surname: '',
				rsoc: '',
				piva: '',
				country: '',
				prov: '',
				city: '',
				address: '',
				cap: '',
				tel: '',
				mail: '',
				username: '',
				password: '',
				privacy: false
			}
		}
	},
	components: {
		EyeSVG
	},
	metaInfo() {
		return{
			title: this.$t('head.title.signin'),
			titleTemplate: '%s | Wearlight',
		}
  },
	watch: {
		'$route.params.locale': function(){
			return{
				title: this.$t('head.title.signin'),
				titleTemplate: '%s | Wearlight',
			}
		}
	},
	methods: {
		...mapActions(['register']),
		sendSignupForm() {
			this.fieldErrors.name = ''
			this.fieldErrors.surname = ''
			this.fieldErrors.rsoc = ''
			this.fieldErrors.piva = ''
			this.fieldErrors.country = ''
			this.fieldErrors.prov = ''
			this.fieldErrors.city = ''
			this.fieldErrors.address = ''
			this.fieldErrors.cap = ''
			this.fieldErrors.tel = ''
			this.fieldErrors.mail = ''
			this.fieldErrors.username = ''
			this.fieldErrors.password = ''
			this.fieldErrors.privacy = false
			if(this.signupName.trim() != '' && this.signupSurname.trim() != '' && this.signupRsoc.trim() != '' && this.signupPiva.trim() != '' && this.signupCountry.trim() != '' && this.signupProv.trim() != '' && this.signupCity.trim() != '' && this.signupAddress.trim() != '' && this.signupCap.trim() != '' && this.signupTel.trim() != '' && this.signupMail.trim() != '' && this.validateEmail(this.signupMail.trim()) && this.signupUsername.trim() != '' && this.signupPassword.trim() != '' && this.signupPrivacy){
				this.$formLoaderVisible = true
				let user = {
					name: this.signupName,
					surname: this.signupSurname,
					rsoc: this.signupRsoc,
					piva: this.signupPiva,
					country: this.signupCountry,
					prov: this.signupProv,
					city: this.signupCity,
					address: this.signupAddress,
					cap: this.signupCap,
					tel: this.signupTel,
					email: this.signupMail,
					username: this.signupUsername,
					password: this.signupPassword,
					lang: localStorage.getItem('lang')
				};
				this.register(user)
				.then(res => {
					this.$formLoaderVisible = false
					if(res.data.success){
						gsap.to(document.querySelector('.signup--wrapper'), {duration: 0.5, autoAlpha: 0, ease: 'power2.out', onComplete: () => {
							gsap.to(document.querySelector('.signup--wrapper'), {duration: 0, delay: 0.05, display: 'none'})
							window.scrollTo(0,0);
							gsap.to(document.querySelector('.response'), {duration: 0, delay: 0.05, display: 'flex'})
							gsap.to(document.querySelector('.response'), {duration: 0.5, delay: 0.5, autoAlpha: 1, ease: 'power2.out'})
						}})
					}else{
						if(res.data.target == 'user'){
							this.fieldErrors.username = res.data.msg;
							gsap.to(document.querySelector('.usr'), {x:"+=20", yoyo:true, repeat:9, duration: 0.15})
						}
						if(res.data.target == 'mail'){
							this.fieldErrors.mail = res.data.msg;
							gsap.to(document.querySelector('.eml'), {x:"+=20", yoyo:true, repeat:9, duration: 0.15})
						}
					}
				}).catch(err => {
					console.log(err);
				})
			}else{
				if(this.signupName.trim() == ''){
					this.fieldErrors.name = this.$t('signup.campo_vuoto')
				}
				if(this.signupSurname.trim() == ''){
					this.fieldErrors.surname = this.$t('signup.campo_vuoto')
				}
				if(this.signupRsoc.trim() == ''){
					this.fieldErrors.rsoc = this.$t('signup.campo_vuoto')
				}
				if(this.signupPiva.trim() == ''){
					this.fieldErrors.piva = this.$t('signup.campo_vuoto')
				}
				if(this.signupCountry.trim() == ''){
					this.fieldErrors.country = this.$t('signup.campo_vuoto')
				}
				if(this.signupProv.trim() == ''){
					this.fieldErrors.prov = this.$t('signup.campo_vuoto')
				}
				if(this.signupCity.trim() == ''){
					this.fieldErrors.city = this.$t('signup.campo_vuoto')
				}
				if(this.signupAddress.trim() == ''){
					this.fieldErrors.address = this.$t('signup.campo_vuoto')
				}
				if(this.signupCap.trim() == ''){
					this.fieldErrors.cap = this.$t('signup.campo_vuoto')
				}
				if(this.signupTel.trim() == ''){
					this.fieldErrors.tel = this.$t('signup.campo_vuoto')
				}
				if(this.signupMail.trim() == '' || !this.validateEmail(this.signupMail.trim())){
					if(this.signupMail.trim() == ''){
						this.fieldErrors.mail = this.$t('signup.campo_vuoto')
					}else{
						this.fieldErrors.mail = this.$t('signup.mail_errata')
					}					
				}
				if(this.signupUsername.trim() == ''){
					this.fieldErrors.username = this.$t('signup.campo_vuoto')
				}
				if(this.signupPassword.trim() == ''){
					this.fieldErrors.password = this.$t('signup.campo_vuoto')
				}
				if(!this.signupPrivacy){
					this.fieldErrors.privacy = true
				}
			}
		},
		togglePassword(){
			document.querySelector('.showPass').classList.toggle('show')
			if(document.querySelector('.showPass').classList.contains('show')){
				document.getElementById('signup-password').setAttribute('type', 'text')
				this.iconShow = false
			}else{
				document.getElementById('signup-password').setAttribute('type', 'password')
				this.iconShow = true
			}
		},
		validateEmail(email){
      return this.reg.test(email);
		}
	},
	mounted() {
		let linkSmall = document.querySelectorAll('.link--mouse-small');
		[...linkSmall].forEach(el => {
			el.addEventListener('mouseenter', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.add('onLink--small')
			})
			el.addEventListener('mouseleave', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.remove('onLink--small')
			})
		});
	}
}
</script>

<style lang="scss">
.signup {
	position: relative;
	width: 100%;
	height: auto;
	padding: 80px 0 0 0;
	@include mobile {
		padding-top: 60px;
	}
	.response {
		position: relative;
		width: 100%;
		height: auto;
		min-height: calc(100vh - 80px);
		display: flex;
		justify-content: center;
		align-items: center;
		display: none;
		visibility: hidden;
		.response--wrapper {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			@include mobile {
				padding: 0 5vw;
			}
			h2 {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0 0 5vh 0;
				span {
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 5vw;
					line-height: 5vw;
					text-transform: uppercase;
					@include mobile {
						font-size: 10vw;
						line-height: 10vw;
					}
					&:first-of-type {
						font-family: 'Saol Display';
						font-weight: 500;
						color: $black;
						font-size: 5vw;
						line-height: 4.5vw;
						text-transform: uppercase;
						display: block;
						margin-bottom: 0.5vw;
						@include mobile {
							font-size: 10vw;
							line-height: 9vw;
						}
					}
				}
			}
			p {
				color: $black;
				font-size: 24px;
				font-weight: 400;
				font-family: 'Suisse Int';
				margin: 0;				
				@include mobile {
					font-size: 16px;
					line-height: 20px;
					text-align: center;
				}
			}
			a {
				background-color: transparent;
				border: 1px solid $black;
				text-transform: uppercase;
				padding: 10px 20px;
				font-family: 'Suisse Int';
				color: $black;
				font-size: 14px;
				line-height: 20px;
				border-radius: 100px;
				width: auto;
				transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
				margin-top: 20px;
				&:hover {
					background-color: $black;
					color: $white;
					transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
				}
			}
		}
	}
	.signup--wrapper {
		position: relative;
		width: 100%;
		height: auto;
		min-height: calc(100vh - 80px);
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		@include mobile {
			min-height: calc(100vh - 60px);
			flex-direction: column;
			padding: 60px 5vw 0 5vw;
			box-sizing: border-box;
		}
		.signup--right {
			position: relative;
			width: 50%;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			padding: 10vh 0;
			@include mobile {
				order: 1;
				border-right: 0;
				width: 100%;
				padding: 0;
			}
			.right--wrap {
				position: relative;
				width: 80%;
				@include mobile {
					width: 100%;
				}
				h1 {
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 5vw;
					line-height: 5vw;
					text-transform: uppercase;
					margin: 0 0 10vh 0;
					@include mobile {
						font-size: 10vw;
						line-height: 10vw;
						margin-bottom: 5vh;
					}
				}
				.form {
					form {
						position: relative;
						width: 100%;
						display: flex;
						align-items: flex-start;
						flex-wrap: wrap;
						.form--item {
							position: relative;
							width: 100%;
							display: inline-flex;
							flex-direction: column;
							margin-bottom: 5vh;
							box-sizing: border-box;
							label {
								font-family: 'Suisse Int';
								text-transform: uppercase;
								color: $black;
								font-size: 14px;
								display: block;
								margin-bottom: 10px;
								@include mobile {
									margin-bottom: 0;
								}
							}
							input {
								position: relative;
								width: 100%;
								font-family: 'Suisse Int';
								color: $black;
								font-size: 20px;
								line-height: 30px;
								border-radius: 0;
								padding: 10px;
								border: 0;
								border-bottom: 1px solid $black;
								box-sizing: border-box;
								&:focus {
									outline: none;
								}
								@include mobile {
									font-size: 16px;
									line-height: 20px;
								}
							}
							&.half {
								width: 50%;
								&.left {
									padding-right: 1vw;
								}
								&.right {
									padding-left: 1vw;
								}
								@include mobile {
									width: 100%;
									padding: 0;
									&.left {
										padding-right: 0;
									}
									&.right {
										padding-left: 0;
									}
								}
							}
							&.third {
								width: calc(100% / 3);
								&.left {
									padding-right: 1vw;
								}
								&.middle {
									padding: 0 1vw;
								}
								&.right {
									padding-left: 1vw;
								}
								@include mobile {
									width: 100%;
									padding: 0;
									&.left {
										padding-right: 0;
									}
									&.middle {
										padding: 0 0;
									}
									&.right {
										padding-left: 0;
									}
								}
							}
							&.twothird {
								width: calc(200% / 3);
								&.left {
									padding-right: 1vw;
								}
								@include mobile {
									width: 100%;
									padding: 0;
									&.left {
										padding-right: 0;
									}
								}
							}
							&.privacy {
								position: relative;
								width: 100%;
								display: flex;
								justify-content: flex-start;
								flex-direction: row;
								input {
									display: none;
								}
								label {
									display: flex;
									justify-content: flex-start;
									flex-direction: row;
									line-height: 20px;
									color: $black;
									font-family: 'Suisse Int';
									text-transform: uppercase;
									@include mobile {
										font-size: 12px;
										line-height: 16px;
										}
									.check {
										position: relative;
										width: 20px;
										height: 20px;
										border: 1px solid $black;
										box-sizing: border-box;
										flex-shrink: 0;
										margin-right: 10px;
										&.selected {
											&:after {
												content: '';
												width: 12px;
												height: 12px;
												position: absolute;
												background-color: $black;
												top: 3px;
												left: 3px;
											}
										}
									}
								}
								&.error {
									.check {
										border-color: $red
									}
									label {
										color: $red;
									}
								}
							}
							.showPass {
								position: absolute;
								bottom: 25px;
								right: 10px;
								width: 20px;
								transform: translateY(50%);
								svg {
									fill: $black;
								}
							}
							.error {
								position: absolute;
								bottom: -20px;
								left: 0;
								width: calc(100% - 1vw);
								text-align: right;
								font-family: 'Suisse Int';
								color: $red;
								font-size: 12px;
								line-height: 20px;
							}
						}
						button {
							background-color: transparent;
							border: 1px solid $black;
							text-transform: uppercase;
							padding: 10px 20px;
							font-family: 'Suisse Int';
							color: $black;
							font-size: 14px;
							line-height: 20px;
							border-radius: 100px;
							width: auto;
							transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
							&:hover {
								background-color: $black;
								color: $white;
								transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
							}
							@include mobile {
								color: $white;
								background-color: $black;
							}
						}
					}
				}
			}
		}
		.signup--left {
			position: relative;
			width: 50%;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			border-right: 1px solid $black;
			padding: 20vh 0;
			@include mobile {
				order: 2;
				width: 100%;
				padding: 10vh 0;
				border-right: 0;
			}
			.left--wrap {
				position: relative;
				width: 60%;
				@include mobile {
					width: 100%;
				}
				h2 {
					margin: 0 0 10vh 0;
					span {
						font-family: 'Saol Display';
						font-weight: 500;
						color: $black;
						font-size: 5vw;
						line-height: 4.5vw;
						text-transform: uppercase;
						display: block;
					}
					@include mobile {
						margin-bottom: 5vh;
						span {
							font-size: 10vw;
							line-height: 9vw;
						}
					}
				}
				a {
					background-color: transparent;
					border: 1px solid $black;
					text-transform: uppercase;
					padding: 10px 20px;
					font-family: 'Suisse Int';
					color: $black;
					font-size: 14px;
					line-height: 20px;
					border-radius: 100px;
					width: auto;
					transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
					&:hover {
						background-color: $black;
						color: $white;
						transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
					}
				}
			}
		}
	}
}
</style>